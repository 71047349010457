import { Container, Row, Col, FloatingLabel, Form, Button } from "react-bootstrap";
import NavbarOp from "../components/Navbar";
import ModalErro from "../components/Modal/ModalErro";
import ModalLoad from "../components/Modal/ModalLoad";
import ModalSucesso from "../components/Modal/ModalSucesso";
import * as Sentry from "@sentry/react";
import axios from "axios";
import React, { useCallback, useEffect } from "react";
import global from "../global";

import { BsArrowLeftSquare } from "react-icons/bs";
import { BsArrowRightSquare } from "react-icons/bs";


export default function ListaNFCe(){

    const [user, setUser] = React.useState(null);
    const [empresaSelecionada, setEmpresaSelecionada] = React.useState(null);
    const [notasNfce, setNotasNfce] = React.useState([]);

    console.log(user);

    const [numNFCe] = React.useState(0);
    const [dateDe, setdateDe] = React.useState(new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString());
    const [dataAte, setdataAte] = React.useState(new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString());
    const [pagina, setPagina] = React.useState(1);
    const [totalPaginas, setTotalPaginas] = React.useState(0);
    const [totalRegistros, setTotalRegistros] = React.useState(0);
    //padrão igual do erp
    const [totalPorPagina] = React.useState(15);

    //modal load
    const [showModalLoad, setShowModalLoad] = React.useState(false);

    //Modal Erro
    const [showModalErro, setShowModalErro] = React.useState(false);
    const [msgModalErro, setMsgModalErro] = React.useState("");

    //Modal Sucesso
    const [showModalSucesso, setShowModalSucesso] = React.useState(false);
    const [msgModalSucesso] = React.useState("");

    //functions
    const countNFCe = useCallback(() => {

        if(empresaSelecionada != null){

            setShowModalLoad(true);
        
            axios.post(global.URL_BASE + global.PATH_CONTAR_NFCE, {
                
                idOrgranizacao: empresaSelecionada.idOrganizacao ,
                idFilial: empresaSelecionada.idEmpresa ,
                numeroNFCe: numNFCe,
                dataEmissaoDe: dateDe,
                dataEmissaoAte: dataAte
    
            })
            .then(function (response) {
    
                console.log(response);
    
                let data = response.data;
    
                if(data.code == 0){
    
                    //
                    setTotalRegistros(data.content);
    
                }else{
    
                    setMsgModalErro(data.msg);
                    setShowModalErro(true);
    
                }
    
            })
            .catch(function (error) {

                Sentry.captureException(error);
                
                setMsgModalErro("Ocorreu um erro ao listar as NFCe. Tente novamente: " + error);
                setShowModalErro(true);
    
                console.log(error);
    
            })
            .finally(function () {
              
                setShowModalLoad(false);

            });

        }

    }, [dataAte, dateDe, empresaSelecionada, numNFCe]);

    const carregarNFCe = useCallback(() => {

        if(empresaSelecionada != null){

            setShowModalLoad(true);
        
            axios.post(global.URL_BASE + global.PATH_LISTANFCe, {
                
                idOrgranizacao: empresaSelecionada.idOrganizacao ,
                idFilial: empresaSelecionada.idEmpresa ,
                numeroNFCe: numNFCe,
                dataEmissaoDe: dateDe,
                dataEmissaoAte: dataAte,
                numeroPagina: pagina,
                numeroPorPagina: totalPorPagina

            })
            .then(function (response) {

                console.log(response);

                let data = response.data;

                if(data.code == 0){

                    console.log("DATA LISTA NFCe" , data)

                    //
                    setNotasNfce(data.content == null ? [] : data.content);
                    countNFCe();

                }else{

                    setMsgModalErro(data.msg);
                    setShowModalErro(true);

                }
                
            })
            .catch(function (error) {

                Sentry.captureException(error);

                console.log(error);

                setMsgModalErro("Ocorreu um erro ao listar as NFCe. Tente novamente: " + error);
                setShowModalErro(true);

            })
            .finally(function () {
                //setShowModalLoad(false);
            });

        }

        

    }, [dataAte, dateDe, empresaSelecionada, numNFCe, pagina, totalPorPagina]);

    console.log("NOTAS NFCe", notasNfce);
    console.log("TOTAL REGISTRO", totalRegistros);
    console.log("PAGINA", pagina);

    useEffect(() => {


        console.log("PAGINA", pagina);
        carregarNFCe();
        

    }, [pagina]);

    useEffect(() => {


        setTotalPaginas( parseInt(totalRegistros / totalPorPagina) +1 );

    }, [totalRegistros, totalPorPagina]);

    return(

        <Container fluid style={{maxWidth: "1000px", paddingBottom: "1%", backgroundColor: "#FFF"}}>

            <ModalErro showModal={showModalErro} setShowModal={setShowModalErro} msg={msgModalErro} />
            <ModalLoad showModal={showModalLoad} setShowModal={setShowModalLoad} msg={msgModalSucesso} />
            <ModalSucesso showModal={showModalSucesso} setShowModal={setShowModalSucesso} msg={msgModalSucesso} />

            <NavbarOp user={user} setUser={setUser} empresaSelecionada={empresaSelecionada} setEmpresaSelecionada={setEmpresaSelecionada} />

            <Row style={{marginTop: "1%", textAlign: "center"}}>
                <h1>NFC-e</h1>
            </Row>

            <hr/>

            <Container style={{marginTop: "1%"}}>
            
            <Row>
                <Col
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                >
                    <FloatingLabel
                        label="De: "
                        className="mb-3"
                    >
                        <Form.Control 
                            type="date"
                            value={dateDe} 
                            max={dataAte}
                            onChange={(e) => {

                                setdateDe(e.target.value);

                            }}
                        />
                    </FloatingLabel>
                </Col>
                <Col
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                >
                    <FloatingLabel
                        label="Até: "
                        className="mb-3"
                    >
                        <Form.Control 
                            type="date"
                            value={dataAte} 
                            max={new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString()}
                            onChange={(e) => {

                                setdataAte(e.target.value);

                            }}
                        />
                    </FloatingLabel>
                </Col>
            </Row>

                {/* <Row>
                    <Col
                    xs={12}
                    sm={6}
                    md={6}
                    >
                        <FloatingLabel
                            label="Numero NFC-e: "
                            className="mb-3"
                        >
                            <Form.Control 
                                type="number"
                                value={numNFCe} 
                                onChange={(e) => {

                                    setnumNFCe(e.target.value);

                                }}
                            />
                        </FloatingLabel>
                    </Col>
                    
                </Row> */}
                

            </Container>

            <Row>
                <Col>
                    {totalRegistros > 0 ? <h6>Total de notas: {totalRegistros}</h6> : null}
                </Col>
            </Row>

            <hr />

            <Row 
            style={{marginBottom: "1%"}}
            
            >
                <Col
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={{marginTop: "1%"}}
                >
                
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {

                            carregarNFCe();

                        }}
                        >Buscar</Button>{' '}
                    </div>
                
                </Col>
                <Col
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={{marginTop: "1%"}}
                >
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {
                            
                            try {

                                setShowModalLoad(true);

                                axios.post(global.URL_BASE + global.PATH_BAIXARXMLNFCe, {
                    
                                    idOrganizacao: empresaSelecionada.idOrganizacao ,
                                    idFilial: empresaSelecionada.idEmpresa ,
                                    numeroNFCe: numNFCe,
                                    dataEmissaoDe: dateDe,
                                    dataEmissaoAte: dataAte
                    
                                })
                                .then(function (response) {
                    
                                    console.log(response);
                    
                                    let data = response.data;
                    
                                    if(data.code == 0){
                    
                                        console.log("DATA LISTA NFCe" , data)
                    
                                        // Decodificar Base64
                                        const base64Content = data.content;
                                        const binaryString = atob(base64Content);
                                        const len = binaryString.length;
                                        const bytes = new Uint8Array(len);
                                        for (let i = 0; i < len; i++) {
                                            bytes[i] = binaryString.charCodeAt(i);
                                        }

                                        // Criar Blob a partir dos dados binários
                                        const blob = new Blob([bytes], { type: 'application/zip' });

                                        // Criar URL para o Blob
                                        const url = window.URL.createObjectURL(blob);

                                        // Criar elemento de link e acionar o download
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', 'notas_nfce.zip'); // Nome do arquivo a ser baixado
                                        document.body.appendChild(link);
                                        link.click();

                                        // Remover o link do DOM
                                        document.body.removeChild(link);
                                        window.URL.revokeObjectURL(url);
                                        
                    
                                    }else{
                    
                                        setMsgModalErro(data.msg);
                                        setShowModalErro(true);
                    
                                    }
                    
                                    setShowModalLoad(false);
                                    
                                })
                                .catch(function (error) {

                                    Sentry.captureException(error);

                                    setMsgModalErro("Ocorreu um erro ao listar as NFCe. Tente novamente: " + error);
                                    setShowModalErro(true);
                                    
                                    setShowModalLoad(false);
                    
                                    console.log(error);
                    
                                });
                                
                            } catch (error) {

                                Sentry.captureException(error);
                                
                                setMsgModalErro(error.message);
                                setShowModalErro(true);
                                setShowModalLoad(false);

                            }

                        }}
                        >Baixar XML</Button>{' '}
                    </div>
                </Col>
            </Row>

            <hr />

            {

                //listar NFC-e
                totalRegistros > 0
                && notasNfce.map((item) => {
                   
                  return(

                    <Container fluid key={item.idNFCe} >
                        <Row>
                            <Col
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            style={{ paddingRight: 1, paddingLeft: 1 }} // Remove paddingRight to remove space between columns
                            >        
                                <FloatingLabel
                                    label="Numero: "
                                    className="mb-0"
                                >
                                    <Form.Control 
                                        type="text"
                                        value={item.numeroNFCe} 
                                        onChange={(e) => {

                                            e.target.value = item.numeroNFCe;

                                        }}
                                    />
                                </FloatingLabel>

                            </Col>
                            <Col
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            style={{ paddingRight: 1, paddingLeft: 1 }} // Remove paddingRight to remove space between columns
                            >
                                
                                <FloatingLabel
                                    label="Chave: "
                                    className="mb-0"
                                >
                                    <Form.Control 
                                        type="text"
                                        value={item.chave} 
                                        onChange={(e) => {

                                            e.target.value = item.chave;

                                        }}
                                    />
                                </FloatingLabel>

                            </Col>
                            <Col
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            style={{ paddingRight: 1, paddingLeft: 1 }} // Remove paddingRight to remove space between columns
                            >

                                <FloatingLabel
                                    label="Status: "
                                    className="mb-0"
                                >
                                    <Form.Control 
                                        type="text"
                                        value={ item.statusFinal} 
                                        readOnly
                                    />
                                </FloatingLabel>

                            </Col>
                            <Col
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            style={{ paddingRight: 1, paddingLeft: 1 }} // Remove paddingRight to remove space between columns
                            >
                                <FloatingLabel
                                    label="Data Emissão: "
                                    className="mb-0"
                                >

                                    <Form.Control 
                                        type="date"
                                        value={new Date(item.dataEmissao).getFullYear().toString() + "-" + ( (new Date(item.dataEmissao).getMonth() + 1) <= 9 ? ("0" + (new Date(item.dataEmissao).getMonth() + 1).toString()) : new Date(item.dataEmissao).getMonth() + 1).toString()  + "-" + ( (new Date(item.dataEmissao).getDate()) <= 9 ? ("0" + new Date(item.dataEmissao).getDate().toString()) : (new Date(item.dataEmissao).getDate().toString()) ).toString()} 
                                        readOnly
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            style={{ paddingRight: 1, paddingLeft: 1 }} // Remove paddingRight to remove space between columns
                            >
                                
                                <FloatingLabel
                                    label="Total nota: "
                                    className="mb-0"
                                >
                                    <Form.Control 
                                        type="text"
                                        value={item.valorTotalNota != null ? item.valorTotalNota.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''} 
                                        onChange={(e) => {

                                            e.target.value = item.valorTotalNota != null ? item.valorTotalNota.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }): '';

                                        }}
                                    />
                                </FloatingLabel>

                            </Col>
                        </Row>

                    </Container>

                  ); 

                })

            }

            {
            totalRegistros > 0 
            &&<div>
                <Row>

                    <hr />

                    <Col>

                        <div className="d-grid gap-2">
                            <Button
                            variant="primary"
                            onClick={() => {

                                console.log("Total Paginas: ", totalPaginas);
                                console.log("Pagina: ", pagina);

                                if(pagina > 1){
                                    setPagina(pagina - 1);
                                }

                            }}
                            ><BsArrowLeftSquare size={30} /></Button>{' '}
                        </div>

                    </Col>
                    
                    <Col>
                        
                        <Form.Control 
                            style={{textAlign: "center", alignItems: "center"}}
                            type="text"
                            value={"Pagina " + pagina + " de " + totalPaginas }
                            onChange={(e) => {

                                e.target.value = "Pagina " + pagina + " de " + totalPaginas;

                            }}
                        />

                    </Col>
                    
                    <Col>

                        <div className="d-grid gap-2">
                            <Button
                            variant="primary"
                            onClick={() => {

                                console.log("Total Paginas: ", totalPaginas);
                                console.log("Pagina: ", pagina);

                                if(pagina < totalPaginas){
                                    setPagina(pagina + 1);
                                }

                            }}
                            ><BsArrowRightSquare size={30} /></Button>{' '}
                        </div>
                    
                    </Col>

                </Row>
            </div>
            }

        </Container>

    );

}
